// import { socket } from "./socket"

import notification from "../assets/audio/notification.mp3";
import notification2 from "../assets/audio/notification2.mp3";
import connected from "../assets/audio/connected.mp3";
import { MyToast } from "../hooks/useToast";
import {
  addNewNotification,
  addOnlyNotification,
  checkConnection,
  setError,
} from "../redux/actions";
import { store } from "../redux/store";

const APP_ENV = process.env.REACT_APP_ENV;

const ChangePath = (path: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  APP_ENV === "development" ? null : (window.location.href = path);
};
// This function fetches the latest realtime notifications
export const GetLatestNotfications = (data: any) => {
  const timer = setTimeout(() => {
    song.pause();
  }, 2000);
  let song = new Audio(notification2);
  try {
    song.play();
  } catch (error) {
    console.log(error);
  }

  MyToast({ title: data.subject.title, description: data.message });
  store.dispatch(
    addOnlyNotification({
      id: Math.floor(Math.random() * 1000),
      name: data.message,
      subject: data.subject.title,
      subText: data.subject.description,
    })
  );
  clearTimeout(timer);
};
// This function fetches the latest realtime notifications only submissions of request
// both fucntion can be merged together , but for reading we created a specific new notification listener

export const GetSubmissionNotfications = (data: any) => {
  const timer = setTimeout(() => {
    song.pause();
  }, 2000);
  let song = new Audio(notification2);
  try {
    song.play();
  } catch (error) {
    console.log(error);
  }
  MyToast({ title: data.subject.title, description: data.message });
  store.dispatch(
    addNewNotification({
      id: Math.floor(Math.random() * 1000),
      name: data.message,
      subject: data.subject.title,
      subText: data.subject.description,
    })
  );
  clearTimeout(timer);
};
export const OnServerClose = () => {
  const timer = setTimeout(() => {
    song.pause();
  }, 3000);
  let song = new Audio(notification);
  try {
    song.play();
  } catch (error) {
    console.log(error);
  }
  MyToast({
    title: "Server Connection Failure",
    description: "There is an Error Occured in Server",
  });
  store.dispatch(setError(["Server closed connection due to Some Resonse"]));
  clearTimeout(timer);
  ChangePath("/maintenance");
  localStorage.setItem("oneSignalPath", "/maintenance");
};
export const OnConnect = () => {
  const timer = setTimeout(() => {
    song.pause();
  }, 2000);
  let song = new Audio(connected);
  try {
    song.play();
  } catch (error) {
    console.log(error);
  }
  store.dispatch(checkConnection(true));
  clearTimeout(timer);
  localStorage.removeItem("oneSignalPath");
};
export function onDisconnect() {
  MyToast({
    title: "Server Connection Failure",
    description: "There is an Error Occured in Server",
  });
  store.dispatch(checkConnection(false));
}
