import { useToast } from "../../hooks/useToast";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import logo from "../../assets/images/logo.png";

function Toaster() {
  const { toasts, dismiss } = useToast();
  return (
    <div
      role="alert"
      aria-live="polite"
      aria-atomic="true"
      className="position-relative"
    >
      <ToastContainer className="p-3" position="top-center">
        {toasts.map(function ({ id, title, description, action, ...props }) {
          return (
            <Toast key={id} delay={3000} autohide onClose={() => dismiss(id)}>
              <Toast.Header>
                <img src={logo} alt="brand-logo" height="32" className="me-1" />
                {title && <strong className="me-auto">{title}</strong>}
                <small className="text-muted">just now</small>
              </Toast.Header>
              {description && <Toast.Body>{description}</Toast.Body>}
            </Toast>
          );
        })}
      </ToastContainer>
    </div>
  );
}

export default Toaster;
